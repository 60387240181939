import Locale from './locale'

document.addEventListener('turbolinks:load', function () {

    var container = $(".search-results");
    var searchContainer = $(".search-field");
    var timeOutFunction;

    function showSearchResults () {
        container.show();
    }

    $(document).mouseup(function(e){
        if(!container.is(e.target) && container.has(e.target).length === 0){
            container.hide();
        }
    });

    $(searchContainer).on('click', function(){
        showSearchResults();
    });

    $(".search-field").on('input', function() {
        $('.search-results').empty();
        clearTimeout(timeOutFunction);
        timeOutFunction = setTimeout(() => {
            var searchValue = $(this).val();
            var trimmedSearchValue = $.trim(searchValue);
            //console.log(searchValue.length)
            if (trimmedSearchValue.length >= 3)  {
                sendTerm(trimmedSearchValue);
            }
        }, 500);
    });

    function sendTerm(searchedTerm) {
        //console.log('term ' + searchedTerm);
        let locale = Locale.getLocale();

        let url = '/' + locale + '/products/quick-search';
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'json',
            data: {
                term: searchedTerm
            }
        }).done(function(data) {
            //console.log(data)
            //console.log(data.items);
            showSearchResults();
            for (let i = 0; i < data.items.length; i++) {
                $('.search-results').append(`
                    <div class="result"> 
                        <div class="result-img"> 
                            <a href="${data.items[i].details_url}">
                                <img src="${data.items[i].image_url}">
                            </a>
                        </div>
                        <div class="result-title"> 
                            <a href="${data.items[i].details_url} "class="product-title"> 
                                ${data.items[i].name} 
                            </a>
                        </div>
                </div> `);
            }

            if(data.items == 0 && locale == 'bg') {               
                $('.search-results').append(`
                    <div class="empty-result">
                        Няма резултати!
                    </div>
                `);
            } 
            if(data.items == 0 && locale == 'en') {                        
                $('.search-results').append(`
                    <div class="empty-result">
                        No results!
                    </div>
                `);
            }

        }).fail(function(xhr, status, error) {
            console.log(error);
        });
    }
})
    
