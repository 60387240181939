import Alerts from './alerts'

document.addEventListener('turbolinks:load', function () {

    //check first name
    // $("#user_first_name").on("input", function(){
    //     var regexp = /[a-zA-Z\u0400-\u04ff]/; // /[^a-zA-Z]/g;
    //     if($(this).val().match(regexp)){
    //         $(this).val( $(this).val().replace(regexp,'') );
    //     }
    // });

    $("#user_first_name").change(function(){
        var firstName = $("#user_first_name").val().length;
        if (firstName < 2) {
            $("#user_first_name").empty();
            Alerts.showAlert("Моля попълнете името си", 'error', true);
        }
    });

    //check last name
    // $("#user_last_name").on("input", function(){
    //     var regexp = /[^a-zA-Z]/g;
    //     if($(this).val().match(regexp)){
    //         $(this).val( $(this).val().replace(regexp,'') );
    //     }
    // });

    $("#user_last_name").change(function(){
        var lastName = $("#user_last_name").val().length;
        if (lastName < 2) {
            $("#user_last_name").empty();
            Alerts.showAlert("Моля попълнете фамилията си", 'error', true);
        }
    });

    //check email
    $("#user_email").change(function(){
        var email = $("#user_email").val();
        var filter = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!filter.test(email)) {
            $("#register_btn").attr("disabled", true);
            Alerts.showAlert("Моля проверете дали правилно сте изписали Имейл адреса си", 'error', true);
        } else {
            $("#register_btn").attr("disabled", false);
        }
    });

    //check if password length > 6
    $("#user_password").change(function(){
        var password_length = $("#user_password").val().length;
        if (password_length < 6) {
            $("#user_password").empty();
            Alerts.showAlert("Паролата трябва да бъде поне 6 символа", 'error', true);
            $("#register_btn").attr("disabled", true);
        }
        else {
            $("#register_btn").attr("disabled", false);
        }
    });

    //check if passwords match
    $("#user_password_confirmation").change(function(){
        var password = $("#user_password").val();
        var retype_password = $("#user_password_confirmation").val();
        if (password !== retype_password) {
            $("#user_password_confirmation").empty();
            Alerts.showAlert("Паролите не съвпадат", 'error', true);
            $("#register_btn").attr("disabled", true);
        }
        else {
            $("#register_btn").attr("disabled", false);
        }
    });

    $("#user_telephone").change(function(){
        var phoneNumber = $("#user_telephone").val();
        this.value = this.value.replace(/[^0-9]/g, '');
        if(phoneNumber.length < 9) {
            Alerts.showAlert("Телефонният номер трябва да е се състои от поне 9 цифри", 'error', true);
        }
    });

    $("#order_client_phone").change(function(){
        var phoneNumber = $("#order_client_phone").val();
        this.value = this.value.replace(/[^0-9]/g, '');
        if(phoneNumber.length < 9) {
            Alerts.showAlert("Телефонният номер трябва да е се състои от поне 9 цифри", 'error', true);
        }
    });
});