var productFilters = (function($) {
  'use strict';

	function init() {
		bindProducerFilter();
		showBrandsFilter();
		bindSortByFilter();
	}

	function bindProducerFilter(){

		$('.js-producer-filter').change(function(){
			$('.js-product-filter-form').submit();
		})
	}

	function showBrandsFilter(){
		$('.brands-filter-btn').click(function(){
			$('.boxes').toggleClass('display-block');
			$('.fa-bars').toggleClass('display-none');
			$('.fa-times').toggleClass('display-block');
		}); 
	}

	function bindSortByFilter(){
		$('.js-products-sort-by').change(function(e){
			var sortFilterValue = this.value;
			var secondInputValue = sortFilterValue;
			$('.js-form-input').val(secondInputValue);
			$('.js-product-filter-form').submit();
		})
	}

  return {
    init: init
  };

})(jQuery);

export default productFilters;