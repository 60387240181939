var order = (function($) {
  'use strict';
	function init() {
		bindDeiveryProviderSelect();
	}

	function bindDeiveryProviderSelect(){
		$('#order_delivery_provider_id').change(function(){
			var selectedOption = $("option:selected", this);
			updateDeliveryProviderData(selectedOption.data('description'));
		})
	}

	function updateDeliveryProviderData(description){
		$('.js-order-delivery-provider-description').animate({'opacity': 0}, 400, function(){
	        $(this).html(description).animate({'opacity': 1}, 400);    
	    });
	//	$('.js-order-delivery-provider-description').html(description).animate({'opacity': 1}, 600); ;
	}

  return {
    init: init
  };

})(jQuery);

export default order;