var alerts = (function($) {
  'use strict';

  function init() {
    // if (!$('#birthday').length) return;

    $('#alerts-container').on('click', '.alert', function(){
      var clickedAlert = $(this);

      setTimeout(function(){ 
        clickedAlert.remove();
      }, 300);
    })

    setTimeout(function(){ 
      $('.alert').fadeOut(1000, function() {
        $(this).remove();
      });
    }, 5000);
  }

  function showAlert(text, alertType, removeOtherAlerts = false){
    // if(text.length < 1){
    //   // no text - ignore
    //   console.log("Ignoring alert. No text provided");
    //   return;
    // }

    var newAlert =  $(`<div class="alert ` + alertType + `">` + text + `</div>`);
    if(removeOtherAlerts){
      clearAlerts();
    }

    $('#alerts-container').prepend(newAlert);

    setTimeout(function(){ 
      (newAlert).fadeOut(1000, function() {
        $(this).remove();
      });
    }, 5000);
  }

  function clearAlerts(){
    $('#alerts-container').html('');
  }

  return {
    init: init,
    showAlert: showAlert,
    clearAlerts: clearAlerts
  };

})(jQuery);

export default alerts;
