document.addEventListener('turbolinks:load', function (event) {

    $('.side_menu').on( "click", function(){
        $('.sidenav').width('250');
        $('.page-wrapper').addClass('blur');
    })

    $('.closebtn').on( "click", function(){
        $('.sidenav').width('0');
        $('.page-wrapper').removeClass('blur');
    })

    $('.mobile-menu-link').each(function() {
        $(this).on("click", function() {
            $('.closebtn').click();
        })
    })

    //Dropdown Button
    $('.navigation li.dropdown .dropdown-btn').on('click', function() {
        $(this).toggleClass('rotate');
        $(this).prev('ul').slideToggle(500).toggleClass('display-block');
    });
});