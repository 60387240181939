document.addEventListener('turbolinks:load', function (event) {
    // Open modal
    $('.js-branch-change').click(function(){
        $('#openModal').addClass('openmodal');
    })

    // Close modal
    $('.close').click(function(){
        $('#openModal').removeClass('openmodal');
    })

    $('.city-button').each(function() {
        $(this).on("click", function() {
            $('#openModal').removeClass('openmodal');
        })
    })
});